import * as React from 'react'
import Layout from '../components/layout'
import { navigate } from "gatsby"

import * as styles from '../styles/form.module.scss'

const FormPage = ({data, location}) => {
return (
    <Layout location = {location} title = "Form Page">
        <form className={styles.formbody}
            onSubmit={ () => {navigate("/formsent/")}} 
            action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSf5h2cUkrGzlgKDF27SJRLm_JZPaHHiD5t0SV0x1CavuT0JQg/formResponse" 
            target="hidden_iframe"
        >
            <h2>お問い合わせ</h2>
            <div className={styles.topbottommargin}>
                <label htmlFor="name">お名前</label>
                <input id="name" type="text" name="entry.32596190" placeholder="お名前" required></input>
            </div>
            <div className={styles.topbottommargin}>
                <label htmlFor="email">メールアドレス</label>
                <input id="email" type="text" name="entry.127582161" placeholder="email@example.com" required></input>
            </div>
            <div className={styles.topbottommargin}>
                <label htmlFor="email">お問い合わせ内容</label>
                <textarea className={styles.biggerform}
                    id="email"
                    type="text"
                    name="entry.511329053"
                    wrap="soft"
                    placeholder="お問い合わせ内容" required></textarea>
            </div>
            <button type="submit">送信</button>
        </form>
        <iframe title="hidden_iframe" name="hidden_iframe" style={{display:'none'}}></iframe>
    </Layout>
)
}
export default FormPage